import api from '../library/axios';

export default {
  getCustomFeature(workspace_id) {
    return api({
      method: 'get',
      url: `/api/custom-features/${workspace_id}`,
    });
  },
};
